<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4 class="font-bold">{{ $t("editOrder") }}</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component
      :is="scrollbarTag"
      class="scroll-area--data-list-add-new"
      :settings="settings"
      :key="$vs.rtl"
    >
      <div
        class="p-6 relative h-full"
        v-if="this.loadingData"
      >
        <spinner-color />
      </div>

      <div class="p-6" v-else>
        <!-- receiver -->
        <div class="relative">
          <label class="input-label font-bold">
            {{ $t("receiver") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            ></label
          >
          <vs-input
            maxlength="100"
            minlength="3"
            v-model="editData.receiver"
            class="w-full"
            name="receiver"
            v-validate="'required|min:3'"
          />
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{
            errors.first("receiver")
          }}</span>
        </div>

        <!-- sender -->
        <div class="mt-5 relative">
          <label class="input-label font-bold"> {{ $t("sender") }} : </label>
          <vs-input
            maxlength="100"
            minlength="3"
            v-model="editData.second_client"
            class="w-full"
            name="secondClient"
          />
        </div>

        <!-- mobeil1 -->
        <div class="mt-5 relative">
          <label class="input-label font-bold">
            {{ $t("number1") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            ></label
          >
          <vue-tel-input
            :defaultCountry="returnDefaultCountry(editData.phone1)"
            mode="international"
            v-model="editData.phone1"
            :inputOptions="internationalInputOptions"
            :validCharactersOnly="true"
            @validate="phoneObject($event, 'phone1')"
            name="number"
            v-validate="'required'"
          ></vue-tel-input>
          <span style="font-size: 0.7rem" class="text-danger font-bold"
            >{{ errors.first("number")
            }}<span v-if="!this.validationsPhone.phone1 && editData.phone1"
              >يجب ادخال رقم هاتف صحيح</span
            ></span
          >
        </div>

        <!-- mobeil2 -->
        <div class="mt-5 relative">
          <label class="input-label font-bold"> {{ $t("number2") }} : </label>
          <vue-tel-input
            v-model="editData.phone2"
            :inputOptions="internationalInputOptions"
            :validCharactersOnly="true"
            :defaultCountry="returnDefaultCountry(editData.phone2)"
            mode="international"
            @validate="phoneObject($event, 'phone2')"
            name="number2"
          ></vue-tel-input>
          <span style="font-size: 0.7rem" class="text-danger font-bold"
            >{{ errors.first("number2")
            }}<span v-if="!this.validationsPhone.phone2"
              >يجب ادخال رقم هاتف صحيح</span
            ></span
          >
        </div>

        <!-- mobileFollow -->
        <div class="mt-5 relative">
          <label class="input-label font-bold">
            {{ $t("numbreTracking") }} :
          </label>
          <vue-tel-input
            v-model="editData.api_followup_phone"
            :inputOptions="internationalInputOptions"
            :validCharactersOnly="true"
            :defaultCountry="returnDefaultCountry(editData.api_followup_phone)"
            mode="international"
            @validate="phoneObject($event, 'api_followup_phone')"
            name="follow_up_phone"
          ></vue-tel-input>
          <span style="font-size: 0.7rem" class="text-danger font-bold"
            >{{ errors.first("mobileFollow")
            }}<span v-if="!this.validationsPhone.api_followup_phone"
              >يجب ادخال رقم هاتف صحيح</span
            ></span
          >
        </div>

        <!-- gov -->
        <div v-if="canEditProductOrGovernment">
          <div class="input-select mt-5">
            <label class="label-select font-bold" ref="govLabel"
              >المحافظة :
              <span style="font-size: 1.2rem" class="text-danger font-bold"
                >*</span
              ></label
            >
            <SelectInputVue
              :default="editData.government_id"
              :edit="true"
              :options="resultGovs"
              v-model="editData.government_id"
              @input="changeRegions(editData.government_id)"
            >
              <template #search>
                <input
                  @blur.stop
                  class="inner-input"
                  v-model="editData.search"
                  @input="searchAll()"
                  placeholder="إبحث هنا"
                />
              </template>
            </SelectInputVue>
          </div>
        </div>

        <!-- area -->
        <div class="mt-5 relative">
          <label class="input-label font-bold">
            {{ $t("area") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            ></label
          >
          <div v-if="!loadingRegions">
            <SelectInputVue
              :default="editData.area_id"
              :edit="true"
              :options="resultRegions"
              v-model="editData.area_id"
              ref="regions"
              name="area"
              v-validate="'required'"
            >
              <template #search>
                <input
                  @blur.stop
                  class="inner-input"
                  v-model="searchAreaWord"
                  @input="searchArea()"
                  placeholder="إبحث هنا"
                />
              </template>
            </SelectInputVue>
            <span style="font-size: 0.7rem" class="text-danger font-bold">{{
              errors.first("area")
            }}</span>
          </div>
          <SkeletonLoadingVue v-else />
        </div>

        <!-- address -->
        <div class="mt-5 relative">
          <label class="input-label font-bold">
            {{ $t("address") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            ></label
          >
          <vs-input
            maxlength="100"
            minlength="3"
            v-model="editData.address"
            class="w-full"
            name="address"
            v-validate="'required|min:3'"
          />
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{
            errors.first("address")
          }}</span>
        </div>

        <!-- amount -->
        <!-- NOTE: Must be warned that the captain can be offline (can't see the new price) -->
        <div class="mt-5 relative">
          <label class="input-label font-bold">
            {{ $t("collectedAmount") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            ></label
          >
          <vs-input
            ref="collectedAmount"
            maxlength="20"
            v-model="editData.amount_to_be_collected"
            class="w-full"
            type="number"
            name="collectedAmount"
            v-validate="
              parseInt(this.order_details.amount_to_be_collected) > 0 && parseInt(this.editData.amount_to_be_collected) < 0 
                  ? 'required|min_value:0'
                  : (parseInt(this.order_details.amount_to_be_collected) < 0 ? 'required|min_value:'+this.order_details.amount_to_be_collected   
                     : 'required|decimal' )
            "
          />
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{
            errors.items.map((item) => item.field === 'collectedAmount' && item.rule === 'rule') 
            && parseInt(this.order_details.amount_to_be_collected) < 0 && parseInt(this.editData.amount_to_be_collected) < this.order_details.amount_to_be_collected
             ? $t('mustBeGreaterThan') + ' ' + this.order_details.amount_to_be_collected
              : errors.first('collectedAmount')
          }}</span>
        </div>

        <!-- reason change_amount_to_be_collected -->
        <div
          v-if="do_change_amount_to_be_collected"
          class="mt-5 relative"
        >
          <label class="input-label font-bold">
            سبب تعديل المبلغ المطلوب تحصيله :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            ></label
          >
          <vs-input
            ref="reasonCollectedAmount"
            maxlength="20"
            v-model="editData.amount_to_be_collected_reason"
            class="w-full"
            type="text"
            name="reasonCollectedAmount"
            v-validate="'required|min:3'"
          />
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{
            errors.first("reasonCollectedAmount")
          }}</span>
        </div>
        <!-- returnValue -->
        <div class="mt-5 relative">
          <label class="input-label font-bold">
            {{ $t("returnValue") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            >
          </label>
          <vs-input
            ref="return_amount"
            maxlength="20"
            v-model="editData.return_amount"
            class="w-full"
            type="number"
            name="returnValue"
            v-validate="'required'"
          />
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{
            errors.first("returnValue")
          }}</span>
        </div>

        <!-- contentOrder -->
        <div class="mt-5 relative">
          <label class="input-label font-bold">
            {{ $t("contentOrder") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            ></label
          >
          <vs-input
            maxlength="100"
            minlength="3"
            v-model="editData.order_summary"
            class="w-full"
            name="contentOrder"
            v-validate="'required'"
          />
        </div>

        <!-- notes -->
        <div class="mt-5 relative">
          <label class="input-label font-bold"> {{ $t("notes") }} : </label>
          <vs-input
            maxlength="250"
            minlength="3"
            v-model="editData.notes"
            class="w-full"
            name="notes"
          />
        </div>

        <!-- invoice -->
        <div class="mt-5 relative">
          <label class="input-label font-bold">
            {{ $t("invoiceNumber") }} :
          </label>
          <vs-input
            ref="invoice_number"
            v-model="editData.invoice_number"
            class="w-full"
            name="invoiceNumber"
          />
        </div>

        <!-- Do given order -->
        <div style="display: flex; align-items: center; flex-wrap: wrap">
          <label class="input-label font-bold mt-5 mb-3 w-full">
            {{ $t("willOrderBe") }}</label
          >
          <span
            v-for="item in payload"
            :key="item.id"
            class="mr-2 flex items-center"
          >
            <input
              style="cursor: pointer"
              :value="item.id"
              v-model="editData.is_order"
              :id="item.id"
              class="custom-control-primary mr-1"
              type="radio"
              name="radiosGroup"
            />
            <label style="cursor: pointer; font-size: 12px" :for="item.id">
              {{ item.name }}
            </label>
          </span>
        </div>

        <!-- Return Summry -->
        <div
          v-if="editData.is_order != 0"
          class="mt-5 relative"
        >
          <label class="input-label font-bold">
            {{ $t("contentReturn") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            ></label
          >
          <vs-input
            maxlength="100"
            minlength="3"
            v-model="editData.return_summary"
            class="w-full"
            name="returnSummry"
            v-validate="'required|min:3'"
          />
        </div>
        <vs-checkbox
          name="checkbox"
          v-model="editData.can_open"
          class="remember m-0 mt-5"
          >يمكن فتح الشحنة ؟</vs-checkbox
        >
        <!-- <vs-checkbox
          name="checkbox"
          v-model="editData.has_packaging_bag"
          class="remember m-0 mt-5"
          >تغليف الطرد ؟</vs-checkbox
        > -->
        <editProductsSection
          :edit="true"
          :canEditProduct="canEditProductOrGovernment"
          ref="products"
          v-if="have_stock === 1"
        />
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <button
        class="mr-6 send-order"
        v-if="!loading"
        :class="{ 'opacity-btn1': !isFormValid }"
        @click="
          googleEventTrack(
            'الضغط على زر حفظ التعديل',
            'edit_order_component',
            'edit_order_action'
          ),
            submitData()
        "
        :disabled="!isFormValid"
      >
        {{ $t("save") }}
      </button>
      <button v-else class="mr-6 flex items-center justify-center send-order">
        <spinner-color />
      </button>
      <button class="cancel-btn" @click="isSidebarActiveLocal = false">
        {{ $t("cancel") }}
      </button>
    </div>

    <!-- Change amount warning -->
    <Modal v-if="showChangeAmountWarning" @closeModal="showChangeAmountWarning = false">
      <template #icon>
        <feather-icon icon="AlertCircleIcon" svgClasses="stroke-current text-primary w-16 h-16" />
      </template>

      <template #content>
        <p class="modal-content w-3/4 my-4">
          {{ 'يرجى العلم انه من الممكن ان يكون الكابتن غير متصل بالانترنت حاليا فبرجاء الاتصال للتأكيد في حالة عدم تأكيد الكابتن بالمعرفة' }}
        </p>
      </template>

      <template #first-btn>
        <button type="button" class="first-btn relative" @click="acceptChangeAmountWarning">
          <span v-if="true">{{ 'حسنا' }}</span>
        </button>
      </template>
    </Modal>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapGetters, mapState } from "vuex";
import SpinnerColor from "./SpinnerColor2.vue";
import Modal from "@/layouts/components/Modal.vue";
import globalMixin from "@/mixins/mixins.js";
import helper from "@/mixins/helper.js";
import orderStatusMixin from "@/mixins/orderStatus.js";
import notifyState from "@/mixins/notifyState";
import editProductsSection from "../../products/SelectProductsSection.vue";
import landLine from "@/mixins/landLine";
import removeSpaceNumber from "@/mixins/removeSpaceNumber.js";
import gTag from "@/mixins/gtag.js";
import SelectInputVue from "@/layouts/components/SelectInput.vue";
import regionsMixins from "@/mixins/regions.js";
import SkeletonLoadingVue from "@/layouts/components/SkeletonLoading.vue";
import validationPhone from "@/mixins/validationPhone.js";
import searchReagions from "@/mixins/searchRegions.js";
import aclAccess from "@/mixins/hasAccess";

export default {
  name: "edit-data",

  mixins: [
    globalMixin,
    helper,
    orderStatusMixin,
    notifyState,
    landLine,
    removeSpaceNumber,
    gTag,
    regionsMixins,
    validationPhone,
    searchReagions,
    aclAccess
  ],

  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    orderId: {
      required: true,
    },
    pageName: {
      type: String,
    },
  },

  components: {
    VuePerfectScrollbar,
    SpinnerColor,
    editProductsSection,
    SelectInputVue,
    SkeletonLoadingVue,
    Modal,
  },

  data() {
    return {
      // NOT WORKING
      // this var should be "false" by default but cause it's bussines needs further validation it will be disabled Temporally
      changeAmountWarningIsAccepted: false,
      showChangeAmountWarning: false,
      editData: {
        receiver: "",
        phone1: "",
        phone2: "",
        api_followup_phone: "",
        area: "",
        area_id: "",
        address: "",
        amount_to_be_collected: "",
        return_amount: "",
        order_summary: "",
        government_id: "",
        is_order: 0,
        return_summary: "",
        second_client: "",
        notes: "",
        search: "",
        government_name: "",
        invoice_number: "",
        amount_to_be_collected_reason: "",
        // has_packaging_bag: 0,
      },
      resultGovs: [],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      loading: false,
      do_change_amount_to_be_collected: false,
      canUpdateAmount: true,
      loadingData: false,
    };
  },
  watch: {
    "editData.amount_to_be_collected": {
      handler: function (newVal, oldVal) {
        if (newVal != this.order_details.amount_to_be_collected) {
          this.do_change_amount_to_be_collected = true;
        } else {
          this.do_change_amount_to_be_collected = false;
        }
      },
      deep: true,
    },
    "editData.phone1"(newVal) {},
  },
  computed: {
    ...mapGetters("dataList", [
      "governments",
      "payload",
      "order_details",
      "success_api",
      "max_order_updated_at",
    ]),
    ...mapGetters("userData", ["have_stock", "total_balance"]),
    ...mapState("externalApi", ["regions"]),
    isFormValid() {
      if (!this.errors.any()) {
        if (
          this.do_change_amount_to_be_collected &&
          !this.editData.amount_to_be_collected_reason 
        ) {
          return false;
        }
        if (
          this.do_change_amount_to_be_collected &&
          !this.canUpdateAmount 
        ) {
          return false;
        }
        if (
          this.editData.is_order == 0 ||
          (this.editData.is_order != 0 &&
            this.editData.return_summary != null &&
            this.editData.return_summary != "")
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    canEditProductOrGovernment() {
      if (
        this.order_details.client_status == this.statusOfOrder.saved ||
        this.order_details.client_status == this.statusOfOrder.sent ||
        this.order_details.client_status == this.statusOfOrder.uncompleted
      ) {
        return true;
      } else {
        return false;
      }
    },
    validateAmountCollected() {
      if(this.order_details.client_status == this.statusOfOrder.saved || this.order_details.client_status == this.statusOfOrder.sent) {
        return 'required|decimal';
      } else {
        this.canUpdateAmount = false;
      }
    },
  },
  methods: {
    ...mapActions("dataList", [
      "postOrder",
      "editOrder",
      "fetchDataListItems",
      "editOrderSubmit",
      "orderDetails",
    ]),
    ...mapActions("externalApi", ["fetchRegions"]),
    searchAll() {
      if (this.editData.search != "") {
        this.resultGovs = this.governments.filter((govs) =>
          govs.name.toLowerCase().includes(this.editData.search.toLowerCase())
        );
      } else {
        this.resultGovs = this.governments;
      }
    },

    acceptChangeAmountWarning() {
      this.changeAmountWarningIsAccepted = true;
      this.showChangeAmountWarning = false;
      this.submitData();
    },

    async submitData() {
      this.$validator.validateAll().then(async (result) => {
        this.loading = true;
        if (this.editData.is_order == 0) {
          this.editData.return_summary = "";
        }

        // METHOD NOT WORKING...
        // the needs further validation so the condition will be Temporally disabled
        // Check (amount_to_be_collected) change
        if (!this.changeAmountWarningIsAccepted) {
          const oldAmount = Number(this.order_details.amount_to_be_collected || 0);
          const newAmount = Number(this.editData.amount_to_be_collected || 0);
          if (oldAmount !== newAmount) {
            if (this.editData.client_status === this.statusOfOrder.withCaptain) {
              this.loading = false;
              this.showChangeAmountWarning = true;
              return;
            }
          }
        }

        // if (this.editData.has_packaging_bag)
        //   this.editData.has_packaging_bag = 1;
        if (result && this.validPhone) {
          if (this.have_stock === 1) {
            let products = [
              ...this.$refs.products.slectedProducts,
              ...this.$refs.products.searchProducts,
            ];
            products = products.filter((product) => product.selected == true);
            if (products.length) {
              this.editData.order_products = products;
            } else this.editData.order_products = "[]";
          }
          this.editData.area = this.$refs.regions.selected.name;
          this.formatNumberPhone("editData", "phone1");
          this.formatNumberPhone("editData", "phone2");
          this.formatNumberPhone("editData", "api_followup_phone");
          await this.editOrderSubmit(this.editData);
          if (this.$store.state.dataList.successApi == 1) {
            this.notifySuccess("editToast");
          } else {
            this.notifyFaild(this.$store.state.dataList.err_msg);
          }
          if (this.pageName != "order/details") {
            let credentials = {
              page: this.pageName,
              pageNum: "",
              itemsPerPage: "",
              maxOrderUpdatedAt: this.max_order_updated_at,
            };
            this.fetchDataListItems(credentials);
          }
          this.$emit("closeSidebar");
        }
        this.loading = false;
      });
    },
  },
  async created() {
    this.loadingData = true;
    this.loadingRegions = true;
    this.resultGovs = this.governments;
    await this.editOrder(this.orderId);
    this.editData = {
      ...this.order_details,
    };
    if (!this.canEditProductOrGovernment)
      this.changeRegions(this.editData.government_id);
    this.loadingData = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_editOrder.scss";
@import "@/assets/scss/vuexy/_product.scss";
</style>

<style lang="scss">
.add-new-data-sidebar {
  .vue-tel-input {
    .vti__input {
      direction: rtl;
    }
  }
}
.product {
  .vs-checkbox-primary .vs-checkbox {
    border: 2px solid #fff !important;
    width: 20px;
    height: 20px;
    margin-right: 11px;
    border-radius: 4px;
  }
  .con-slot-label {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 27px;
  }
}
</style>
