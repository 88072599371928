import allStatus from '@/views/status/orderStatus'
export default {
    data(){
        return{
            statusOfOrder: allStatus,
        }
    },
    computed: {
    },
    methods: {
        returnOrderStatus(status){
            if(status == this.statusOfOrder.saved){
                return 'statusSaved'
            }else if(status == this.statusOfOrder.sent){ 
              return 'statusSent'
            }else if(status == this.statusOfOrder.underway){
              return 'statusUnderWay'
            }else if(status == this.statusOfOrder.withCaptain){ 
              return 'statusWithCaptain'
            }else if(status == this.statusOfOrder.withCompany){
              return 'statusWithCompany'
            }else if(status == this.statusOfOrder.returned){ 
              return 'statusReturned'
            }else if(status == this.statusOfOrder.given){ 
              return 'statusGiven'
            }else if(status == this.statusOfOrder.wallet){ 
              return 'statusWallet'
            }else if(status == this.statusOfOrder.deleted){ 
              return 'statusDeleted'
            }else if(status == this.statusOfOrder.resend){ 
              return 'statusResend'
            }else if(status == this.statusOfOrder.missing){ 
              return 'statusMissing'
            }else if(status == this.statusOfOrder.expiredReturn){ 
              return 'statusExpiredReturn'
            }else if(status == this.statusOfOrder.delayed){ 
              return 'statusDelayed'
            }else if (status == this.statusOfOrder.uncompleted) {
              return 'statusUncompleted'
            }
        }
    },
    created(){
    }
}